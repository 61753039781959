<template>
    <div class="gift_recharge_pop">
        <van-popup v-model="giftpayShow" @close="close" :lock-scroll="true" :closeOnClickOverlay='false' style="background:none">
            <div class="payPage">
                <div class="content">
                    <div class="payText">
                        <span class="title" v-html="titleTexts[changeRechargeType].title"></span>
                        <span class="payTip" v-html="titleTexts[changeRechargeType].tips"></span>
                    </div>
                    </div>
                <div class="buttom">
                    <span class="btn cancel" @click="close" @paste="handlePaste">{{ $t('m.cancel') }}</span>
                    <span class="btn confirm" @click="receiveGiftBag" @paste="handlePaste">{{ $t('m.confirm') }}</span> 
                    <!-- 之前的购买礼包 -->
                    <!-- <span class="btn confirm" @click="build_payment">{{ $t('m.confirm') }}</span>  -->
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
export default({
    props: {
        //充值类型 1：领取礼包  2：礼包下金币充值  
        rechargeType: {
            type: Number,
            default: 1
        },
        giftpayShow: {
            type: Boolean,
            default: false
        },
        payData: {
            type: Object,
            default: {}
        },
        curreny: {            
            type: String,
            default: {}
        },
        lang: {            
            type: Number,
            default: 1
        }
    },
    data(){
        return {
            titleTexts: {
                //默认
                0: {
                    title: '',
                    tips: ''
                },
                //未领取礼包
                1: {
                    title: '',
                    tips: ''
                },
                //已领取礼包
                2: {
                    title: '',  //金币
                    tips: ''
                },
                //更换礼包
                3: {
                    title: '',  //金币
                    tips: ''
                }
            },
            changeRechargeType: 0
        }   
    },
    created(){
        console.log('--------------------------- rechargePop.vue 领取或更换礼包弹窗 -----------------------')
        console.log(this.lang, '..............lang')
        console.log(JSON.parse(JSON.stringify(this.payData)), '.................payData')
        this.changeRechargeType = this.rechargeType
        console.log(this.changeRechargeType, '................changeRechargeType')  

        let _userGiftpackClaimStatus = Number(sessionStorage.getItem('giftpackClaimStatus'))
        this.changeRechargeType = _userGiftpackClaimStatus

        //领取礼包二次确认弹窗文案
        this.titleTexts[this.changeRechargeType].title = this.$t('m.text6-7', {0: this.payData.objective, 1:this.payData.valid_day})
        if(this.lang === 1 && this.payData.valid_day <= 1) {
            this.titleTexts[this.changeRechargeType].title = this.titleTexts[this.changeRechargeType].title.replace(/days/gi, "day")
        }
        this.titleTexts[this.changeRechargeType].tips = this.$t('m.text3-8')        
        console.log('弹窗文案..........', this.titleTexts)      
        
        // if(this.changeRechargeType === 1) {
        //     console.log('未领取礼包.........')
        //     this.titleTexts[this.changeRechargeType].title = this.$t('m.text6-7', {0: this.payData.objective, 1:this.payData.valid_day})
        //     if(this.lang === 1 && this.payData.valid_day <= 1) {
        //         this.titleTexts[this.changeRechargeType].title = this.titleTexts[this.changeRechargeType].title.replace(/days/gi, "day")
        //     }
        //     this.titleTexts[this.changeRechargeType].tips = this.$t('m.text3-8')
        // } else if(this.changeRechargeType === 2) {
        //     console.log('已领取礼包后更换礼包.........')
        //     this.titleTexts[this.changeRechargeType].title = this.$t('m.text4-7')       
        //     this.titleTexts[this.changeRechargeType].tips = this.$t('m.text5-7')    
        // } 
        //  else if(this.rechargeType === 3) {
        //     console.log('已领取礼包后更换礼包.........')
        //     // this.titleTexts[this.rechargeType].title = this.$t('m.hasbuyGiftpack')
        //     // this.titleTexts[this.rechargeType].tips = this.$t('m.hasbuyGiftpackdesc') + this.$t('m.hasbuyGiftpackdescred')
        //     this.titleTexts[this.rechargeType].title = this.$t('m.text4-7')   
        //     this.titleTexts[this.rechargeType].tips = this.$t('m.text5-7')                       
        // }        
    },
    methods: {  
        handlePaste(){
            event.preventDefault(); 
        },  
        getTitle(type){
            if(type === 1) {
                return this.titleTexts[this.changeRechargeType].title
            } else if(type === 2)
                return this.titleTexts[this.changeRechargeType].tips
        },    
        close() {
            this.$emit('closePop')
        },
        // 确认购买（之前的旧方法）
        handlerConfirm(){
            if(this.changeRechargeType !== 3) {
                this.build_payment()
            } else {

            }
        },
        // （之前的旧方法）
        gotoConfimbuy(){
            this.$emit('gotoConfirmbuygift', {data: this.payData, type:1})
        },
        // 构建订单（之前的旧方法）
        build_payment() {
            this.$emit('handlerBuildPayment', {data: this.payData, pay_method: this.changeRechargeType})
        },
        receiveGiftBag(event){
            event.preventDefault(); 
            this.$emit('handlerReceiveGiftBag', {data: this.payData, rechargeType: this.changeRechargeType})
        }
    }
})
</script>